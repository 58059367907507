import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";
import * as Scroll from 'react-scroll';
import logo from '../../images/icon/DIATTACK.svg';

class Topbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            page: false,
            landing: false,
            docs: false,
            new: false,
            utility: false,
            user: false,
            work: false,
            blog: false,
            carr: false
        };
        this.toggleLine = this.toggleLine.bind(this);
    }

    toggleLine() {
        this.setState(prevState => ({ isOpen: !prevState.isOpen }));
    }

    componentDidMount() {
        var matchingMenuItem = null;
        var ul = document.getElementById("top-menu");
        var items = ul.getElementsByTagName("a");
        {/*for (var i = 0; i < items.length; ++i) {
             if (this.props.location.pathname === items[i].pathname) {
                 matchingMenuItem = items[i];
                 break;
             }
         }
         if (matchingMenuItem) {
            this.activateParentDropdown(matchingMenuItem);
         }*/}

    }

    activateParentDropdown = (item) => {
        const parent = item.parentElement;
        if (parent) {
            parent.classList.add('active'); // li
            const parent1 = parent.parentElement;
            parent1.classList.add('active'); // li
            if (parent1) {
                const parent2 = parent1.parentElement;
                parent2.classList.add('active'); // li
                if (parent2) {
                    const parent3 = parent2.parentElement;
                    parent3.classList.add('active'); // li
                    if (parent3) {
                        const parent4 = parent3.parentElement;
                        parent4.classList.add('active'); // li
                    }
                }
            }
        }
    }

    slideToService = () => {
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(610);
    }

    slideToPlatform = () => {
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(2050);
    }
    slideToHome = () => {
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(0);
    }
    render() {
        return (
            <React.Fragment>
                <header id="topnav" className="defaultscroll sticky">
                    <div className="container">
                        <div >
                            {
                                window.location.href.endsWith("page-team") == true ? <Link className="logo" to="/index"> <img height="60" src={logo} alt="" /></Link> :
                                    <>
                                        {
                                            window.location.href.endsWith("contact-us") == true ? <Link className="logo" to="/index"> <img height="60" src={logo} alt="" /></Link> :
                                                <Link className="logo" onClick={this.slideToHome}> <img height="60" src={logo} alt="" /></Link>
                                        }
                                    </>
                            }
                        </div>
                        <div className="menu-extras">
                            <div className="menu-item">
                                <Link to="#" onClick={this.toggleLine} className={this.state.isOpen ? "navbar-toggle open" : "navbar-toggle"} >
                                    <div className="lines">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </Link>
                            </div>
                        </div>

                        <div id="navigation" style={{ display: this.state.isOpen ? "block" : "none" }}>
                            <ul className="navigation-menu" id="top-menu">
                                <li><Link onClick={this.slideToHome} to="/index">Home</Link></li>
                                <li><Link onClick={this.slideToService} to="/services">Services</Link></li>
                                <li><Link onClick={this.slideToPlatform} to="/platform">Platform</Link></li>
                                <li><Link to="/contact-us">Contact Us</Link></li>

                            </ul>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}

export default withRouter(Topbar);