// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import Particles from 'react-particles-js';

//import images
import enterprise from '../../images/istanbul-bg.jpg';
import roundWhite from '../../images/shapes/round-white.png';

// import generic componenets
import Feature from './Feature';
import Contact from './Contact';
import Testi from './Testi';
import Architecture from './Architecture';
import ParticleComponent from './ParticleComponent'
// import Work from './Work';

import * as Scroll from 'react-scroll';

class Index extends Component {

    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
            document.body.classList = "";
            const doc = document.getElementById('topnav')
            if(doc){
                document.getElementById('topnav').classList.add('bg-white');
                window.addEventListener("scroll", this.scrollNavigation, true);
            }
    }
    // Make sure to remove the DOM listener when the component is unmounted.
    componentWillUnmount() {
        window.removeEventListener("scroll", this.scrollNavigation);
    }

    scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);

        if (doc && document.getElementById('topnav').classList && top > 80) {
            document.getElementById('topnav').classList.add('nav-sticky');
        }
        else {
            document.getElementById('topnav').classList.remove('nav-sticky');
        }
    }

    slideToContact = () =>{
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(2600);
    }

    render() {

        return (
            <React.Fragment>

                {/* <div id="preloader">
                    <div id="status">
                        <div className="spinner">
                            <div className="double-bounce1"></div>
                            <div className="double-bounce2"></div>
                        </div>
                    </div>
                </div> */}

                {/* Hero Start */}
                <section className="bg-half-170 w-100" style={{ background: `url(${enterprise}) center center` }}>
    
                    <div className="bg-overlay"></div>
                    <ParticleComponent />
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <Row>
                                    <Col>
                                        <div className="title-heading text-center mt-4">
                                            <h1 className="display-4 text-white mb-3">Predictive Security Services</h1>
                                            <p className="para-desc mx-auto text-white">Respond in real-time to critical events to avoid security breaches leading to corporate information, reputation, system, earnings, business and time losses.</p>
                                            <div className="mt-4 pt-2">
                                                <Link to="#" onClick={this.slideToContact} className="btn btn-light">Contact Now</Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>

                            <div className="container-fluid">
                                <Row>
                                    <div className="home-shape-bottom">
                                        <img src={roundWhite} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Feature */}
                <Feature />

                {/* Testi */}
                <Testi />

                {/* Price */}
                <Architecture />

                {/* Contact */}
                <Contact />

            </React.Fragment>
        );
    }
}

export default Index;
