// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Alert } from 'reactstrap';

//import images
import pen from '../../images/icon/pen.svg';
import video from '../../images/icon/video.svg';
import user from '../../images/icon/user.svg';
import calendar from '../../images/icon/calendar.svg';
import sandClock from '../../images/icon/sand-clock.svg';
import intellectual from '../../images/icon/intellectual.svg';
import MobileNotificationSVG from '../../images/illustrator/Mobile_notification_SVG.svg';

class Feature extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Contactvisible : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({Contactvisible : true});
    }  

    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <Row className="justify-content-center">
                            <Col className="text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="title mb-4">What We Do ?</h4>
                                    <p className="text-muted para-desc mx-auto mb-0">Start working with <span className="text-primary font-weight-bold">Diattack</span> that can provide everything you need to proactive security requirements</p>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={pen} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Predictive Security</h4>
                                        <p className="text-muted mb-0">Predictive Security have the power to proactively help businesses identify security threats before they can do any damage.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={video} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">AI Based Phishing Detection</h4>
                                        <p className="text-muted mb-0">Email security solutions must be predictive in their approach to threat detection. AI makes it possible.You may be interested in our product <a target="_blank" href="http://www.phishup.co"> PhishUp</a></p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={intellectual} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">ML Based Sandbox</h4>
                                        <p className="text-muted mb-0">Detect malware samples which do not execute or do not show any malicious behavior during the dynamic execution.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={user} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Big Data Solutions</h4>
                                        <p className="text-muted mb-0">Our team works with a wide technology stack, including Apache Hadoop, Apache Spark, Apache Storm, Apache Cassandra and other frameworks.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={calendar} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Elasticsearch SIEM</h4>
                                        <p className="text-muted mb-0">Leverage the speed, scale, and relevance of Elastic SIEM to drive your security operations and threat hunting.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="col-12 mt-5">
                                <div className="features text-center">
                                    <div className="image position-relative d-inline-block">
                                        <img src={sandClock} alt="" />
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Cyber KPI Dashboards</h4>
                                        <p className="text-muted mb-0">Cyber KPI Dashboard is the most visible part of an integrated data and analytics platform for holistic digital risk management.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Feature;