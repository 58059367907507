import React, { Component, useState } from 'react';
import { Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import * as Scroll from 'react-scroll';
import { useFormik } from 'formik';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import axios from 'axios';
import {subscribeservicepath} from '../../costants/defaultValues'


export const Footer = () => {
    const [sent, setSent] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const [emailExist, setEmailExist] = useState(false);


    const slideToHome = () => {
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(0);
    }

    const slideToService = () => {
        var scroll = Scroll.animateScroll;
        scroll.scrollTo(610);
    }

    const counterSuccess = () => {
        setInterval(() => {
            setSent(false)
        }, 3000);
    }

    const counterExist = () => {
        setInterval(() => {
            setEmailExist(false)
        }, 3000);
    }

    const counterError = () => {
        setInterval(() => {
            setErrorState(false)
        }, 3000);
    }


    const formik = useFormik({
        initialValues: {
            email: "",
        },
        onSubmit: values => {
            const dataHolder = {
                email: values.email,
            }
            axios.post(subscribeservicepath, dataHolder).then(resp => {
                if (resp.status === 201) {
                    setSent(true)
                }
                else if (resp.status === 409) {
                    setEmailExist(true)
                }
                else{
                    setErrorState(true)
                }
            })
            counterSuccess()
            counterExist()
            counterError()
            values.email = ""
        },
    });

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-12 mb-0 mb-md-4 pb-0 pb-md-2">

                            <div className="col-lg-10">
                                <Link className="logo-footer" to="#">Diattack</Link>

                                <p className="mt-4">DIATTACK Provides quick action by detecting misused accounts, data loss stories, insider threats and network layer anomalies.</p>
                                {
                                    /*<ul className="list-unstyled social-icon social mb-0 mt-4">
                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-facebook" title="Facebook"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#" className="rounded mr-1"><i className="mdi mdi-instagram" title="Instagram"></i></Link></li>
                                        <li className="list-inline-item"><Link to="#" className="rounded"><i className="mdi mdi-twitter" title="Twitter"></i></Link></li>
                                    </ul>*/
                                }
                                <ul className="list-unstyled social-icon social mb-0 mt-4">
                                    <li className="list-inline-item"><a target="_blank" href="https://www.linkedin.com/company/diattack/" className="rounded mr-1"><i className="mdi mdi-linkedin" title="Linkedin"></i></a></li>
                                </ul>
                            </div>

                        </div>

                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="">
                                <h4 className="text-light footer-head d-flex justify-self-center">Company</h4>
                                <ul className="list-unstyled footer-list mt-4">
                                    <li><Link onClick={() => slideToHome()} to="/index" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Home</Link></li>
                                    <li><Link onClick={() => slideToService()} to="/services" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Services</Link></li>
                                    <li><Link to="/page-team" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Team</Link></li>
                                    <li><Link to="/contact-us" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Contact Us </Link></li>
                                </ul>
                            </div>
                        </div>

                        {/* <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                        <h4 className="text-light footer-head">Usefull Links</h4>
                        <ul className="list-unstyled footer-list mt-4">
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Privacy Protection</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Safe Payments</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Terms of Services</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Documentation</Link></li>
                            <li><Link to="#" className="text-foot"><i className="mdi mdi-chevron-right mr-1"></i> Privacy Policy</Link></li>
                        </ul>
                    </div>*/}
                        <div className="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            {emailExist &&
                                <Alert color="info" >
                                    User already subscribed!
                                </Alert>}
                            {errorState &&
                                <Alert color="danger" >
                                    An error occurred while sending your request! Please try again later.
                                </Alert>}
                            <h4 className="text-light footer-head">Newsletter</h4>
                            <p className="mt-4">Sign up and receive the latest tips via email.</p>
                            <form onSubmit={formik.handleSubmit}>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="foot-subscribe form-group position-relative">
                                            <label>Write your email <span className="text-danger">*</span></label>
                                            <i className="mdi mdi-email ml-3 icons"></i>
                                            <input type="email" name="email" id="emailsubscribe" className="form-control pl-5 rounded" placeholder="Your email : " required onChange={formik.handleChange} value={formik.values.email} />
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        {sent == false ? <input type="submit" id="submitsubscribe" name="send" className="btn btn-primary w-100" value="Subscribe" /> : <input type="submit" id="submitsubscribe" name="send" className="btn btn-success w-100" value="Successfull" disabled />}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </footer>
            <hr />
            <footer className="footer footer-bar">
                <div className="container text-center">
                    <div className="row align-items-center">
                        <div className="col-sm-12">
                            <div className="text-sm-left">
                                <p className="mb-0">©  {new Date().getFullYear()}  Diattack Develop by Diatics.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </React.Fragment>
    );

}

export default Footer;
