import React from 'react';

// Import all components
const Enterprise = React.lazy(() => import('./pages/Enterprise/index'));
const PageContactTwo = React.lazy(() => import('./pages/PageContactTwo'));
const PageTeam = React.lazy(() => import('./pages/PageTeam'));

const routes = [

    // public Routes
    { path: '/index', component: Enterprise },
    { path: '/services', component: Enterprise },
    { path: '/platform', component: Enterprise },
    { path: '/contact-us', component: PageContactTwo },

];

export default routes;