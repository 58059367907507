// React Basic and Bootstrap
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';

//import images
import img3 from '../../images/dia-background.jpg';
import roundWhite from '../../images/shapes/round-white.png';
import client01 from '../../images/client/01.jpg';
import client02 from '../../images/client/02.jpg';
import client03 from '../../images/client/03.jpg';
import client04 from '../../images/client/04.jpg';
import client05 from '../../images/client/05.jpg';
import client06 from '../../images/client/06.jpg';

// RBCarousel Declare
import RBCarousel from "react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "../../css/carousel.css";

class Testi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            autoplay: true
        }
    }

    onSelect = (active, direction) => {
        console.log(`active=${active} && direction=${direction}`);
    };
    visiableOnSelect = active => {
        // console.log(`visiable onSelect active=${active}`);
    };
    slideNext = () => {
        this.slider.slideNext();
    };
    slidePrev = () => {
        this.slider.slidePrev();
    };
    goToSlide = () => {
        this.slider.goToSlide(4);
    };
    autoplay = () => {
        this.setState({ autoplay: !this.state.autoplay });
    };
    _changeIcon = () => {
        let { leftIcon, rightIcon } = this.state;
        leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
        rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
        this.setState({ leftIcon, rightIcon });
    };

    render() {
        return (
            <React.Fragment>
                <section className="section" style={{ background: `url(${img3})`,backgroundPosition : "center", backgroundRepeat:"no-repeat" }}>
                    <div className="bg-overlay"></div>
                    <div className="container">
                        <Row className="pt-5 pb-5 justify-content-center">
                            <Col lg={8} className="text-center">
                                <div id="owl-fade" className="owl-carousel owl-theme">

                                    <RBCarousel
                                        version={4}
                                        autoplay={this.state.autoplay}
                                        pauseOnVisibility={true}
                                        onSelect={this.visiableOnSelect}
                                        slideshowSpeed={3000}
                                    >
                                        <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <h2 className="text-light ">CIOs must engage in digital risk management</h2>
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">"CIOs must become the central agent stressing the connections between business and digital risk. The key is to build influence across business units and ecosystems to better manage security and risk, find the right talent and ensure appropriate levels of protection."</p>
                                                <h6 className="text-light">Gartner </h6>
                                            </div>
                                        </div>
                                       {/* <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <h2 className="text-light ">CIOs must engage in digital risk management</h2>
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">" Employee-Reported Phishing Attacks Saddle SOCs With 60% False Positives. "</p>
                                                <h6 className="text-light">Agari Report </h6>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <img src={client03} height="90" className="rounded-pill mx-auto shadow" alt="" />
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">" There is now an abundance of readable dummy texts. These are usually used when a text is required purely to fill a space. These alternatives to the classNameic Lorem Ipsum texts are often amusing and tell short, funny or nonsensical stories. "</p>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                </ul>
                                                <h6 className="text-light"> Barbara McIntosh </h6>
                                            </div>

                                        </div>
                                        <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <img src={client04} height="90" className="rounded-pill mx-auto shadow" alt="" />
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero in 45 BC. Allegedly, a Latin scholar established the origin of the text by compiling all the instances of the unusual word 'consectetur' he could find "</p>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                </ul>
                                                <h6 className="text-light"> Christa Smith </h6>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <img src={client05} height="90" className="rounded-pill mx-auto shadow" alt="" />
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "</p>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                </ul>
                                                <h6 className="text-light"> Dean Tolle </h6>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="customer-testi text-center mx-4">
                                                <img src={client06} height="90" className="rounded-pill mx-auto shadow" alt="" />
                                                <p className="text-light h5 font-weight-normal font-italic mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. One may speculate that over the course of time certain letters were added or deleted at various positions within the text. "</p>
                                                <ul className="list-unstyled mb-0">
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                    <li className="list-inline-item"><i className="mdi mdi-star text-warning"></i></li>
                                                </ul>
                                                <h6 className="text-light"> Jill Webb </h6>
                                            </div>
                                        </div>*/}
                                    </RBCarousel>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="container-fluid">
                        <Row>
                            <div className="home-shape-bottom">
                                <img src={roundWhite} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Testi;