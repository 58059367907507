// React Basic and Bootstrap
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

// RBCarousel Declare
import RBCarousel from "react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import "../../css/carousel.css";

// Import images
import analyzeReport4 from '../../images/illustrator/DiAttacks.svg';




class Architecture extends Component {

    constructor(props) {
        super(props);
        this.state = {
  
        }
    }


    render() {
        return (
            <React.Fragment>
                <section className="section">
                    <div className="container">
                        <Row className="align-items-center">

                            <Col md={5} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="section-title mr-lg-5">
                                    <h4 className="title mb-4">Realtime Architecture</h4>
                                    <p className="text-muted">DIATTACK is a Next-Gen SIEM platform that unifies data collection, machine learning and analytics for next gen threat detection and response. It’s built on a scalable big data architecture and uses data science for machine learning, threat intelligence, anomaly detection, pattern recognition, behavioral analysis and advanced analytics.</p>
                                    {/*<Link to="#" className="btn btn-outline-primary">Start Now <i className="mdi mdi-chevron-right"></i></Link>*/}
                                </div>
                            </Col>

                            <Col md={7}>
                                <img src={analyzeReport4} className="mr-md-4" alt="" />
                            </Col>
                        </Row>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default Architecture;
