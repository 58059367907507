// React Basic and Bootstrap
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
//import images
import client06 from '../../images/client/06.jpg';

// import images
import bitcoin from '../../images/icon/bitcoin.svg';
import Email from '../../images/icon/Email.svg';
import location from '../../images/icon/location.svg';


class Testi extends Component {

    constructor(props) {
        super(props);
        this.state = {
            autoplay: true
        }
    }

    onSelect = (active, direction) => {
        console.log(`active=${active} && direction=${direction}`);
    };
    visiableOnSelect = active => {
        // console.log(`visiable onSelect active=${active}`);
    };
    slideNext = () => {
        this.slider.slideNext();
    };
    slidePrev = () => {
        this.slider.slidePrev();
    };
    goToSlide = () => {
        this.slider.goToSlide(4);
    };
    autoplay = () => {
        this.setState({ autoplay: !this.state.autoplay });
    };
    _changeIcon = () => {
        let { leftIcon, rightIcon } = this.state;
        leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
        rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
        this.setState({ leftIcon, rightIcon });
    };


    

    render() {
        return (
            <React.Fragment>

                <section class="section">
                    <div class="container">
                        <div class="justify-content-center row">
                            <div class="text-center col-12">
                                <div class="section-title">
                                    <h4 class="title mb-4">Connect our business with more secure today</h4>
                                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span class="text-primary font-weight-bold">Diattack</span> that can provide things you need to predictive security and big data requirements.</p></div>
                                <div class="contact-detail mt-5">
                                   </div></div></div></div>
                                   <div className="container">
                        <Row>
                            <Col md={4}>
                                <div className="contact-detail text-center">
                                    <div className="icon">
                                        <img src={bitcoin} alt="Landrick" />
                                    </div>
                                    <div className="content mt-3">
                                        <h4 className="title font-weight-bold" >Phone</h4>
                                        <p className="text-muted">Start working with Diattack that can provide everything</p>
                                        <a href="tel:+90 216 706 68 20" className="text-primary">+90 216 706 68 20</a>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="contact-detail text-center">
                                    <div className="icon">
                                        <img src={Email} alt="Landrick" />
                                    </div>
                                    <div className="content mt-3">
                                        <h4 className="title font-weight-bold">Email</h4>
                                        <p className="text-muted">Start working with Diattack that can provide everything</p>
                                        <a href="mailto:info@diatics.com" className="text-primary">info@diatics.com</a>
                                    </div>
                                </div>
                            </Col>

                            <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                                <div className="contact-detail text-center">
                                    <div className="icon">
                                        <img src={location} alt="Landrick" />
                                    </div>
                                    <div className="content mt-3">
                                        <h4 className="title font-weight-bold">Location</h4>
                                        <p className="text-muted">Sanayi Mah. Teknopark İstanbul 4C Apt. 1/4C <br />İstanbul, TURKEY</p>
                                        <a target="_blank" href="https://www.google.com/maps?ll=40.92367,29.316075&z=14&t=m&hl=tr&gl=TR&mapclient=embed&cid=4454143398444511815" className="video-play-icon h6 text-primary">View on Google map</a>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                                   
                                   </section>
            </React.Fragment>
        );
    }
}

export default Testi;